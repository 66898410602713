import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';

import downloadFile from './pitto.pdf' 
import documentLogo from 'common/assets/image/saasModern/document.png';

const DocumentSection = ({
  sectionWrapper,
  row,
  title,
  image,
  description,
  textArea,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...row}>
          <Box {...textArea}>
            <Heading {...title} content='まずは資料をご覧ください' />
            <Text {...description} content='' />
          </Box>
                       <Image
             {...image}
                  src={documentLogo}
                  alt={`資料請求`}
                />
                      <div class='btn_qForm_wrapper'>
              <a
                href={downloadFile}
                download="pitto説明資料.pdf"
                class='btn_qForm bgskew'
              >
                <span>資料ダウンロード</span>
              </a>
            </div>
        </Box>
      </Container>
    </Box>
  );
};

DocumentSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object,
};

DocumentSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    className: 'trial-section',
    pt: ['50px', '40px', '60px', '80px'],

    height: ['100vh'],
    zIndex: 1,

  },image:{
    height:"300px"
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    fontSize: ['20px', '26px', '26px', '30px', '36px'],
        pt: ['50px', '40px', '60px', '80px'],
    fontWeight: '700',
    color: '#808080',
    letterSpacing: '-0.025em',
    mb: '28px',
    textAlign: 'center',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['15px', '15px', '16px', '16px', '16px'],
    color: '#5c636c',
    lineHeight: '2.1',
    textAlign: 'center',
    mb: ['50px', '35px', '40px', '60px'],
  },

};

export default DocumentSection;
